<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="name"
              placeholder="Ex.: Seguro de vida"
              v-model="name"
              :class="{ 'is-invalid': v$.name.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Modelo de distribuição das comissões"
            label-for="network-distribution"
          >
            <v-select
              v-model="network_distribution_id"
              :reduce="network_distribution => network_distribution.id"
              :options="networkDistributions"
              label="name"
              dir="ltr"
              :class="getSelectErrorClass(v$.network_distribution_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.network_distribution_id.$error">
                Você deve informar um modelo
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Descrição"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="description"
              :class="{ 'is-invalid': v$.description.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.description.required.$invalid">
                Você deve informar uma descrição
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'products-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="loading || saving"
              @click.prevent="save"
            >
              <b-spinner
                v-if="saving"
                small 
              />
              <feather-icon
                v-else
                icon="SaveIcon"
                class="mr-50"
              />
              <span>
                {{ saving ? 'Salvando...' : 'Salvar' }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getVueSelectErrorClass } from '@/helpers/validators'
import * as types from '../store/types'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast() , v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      saving: false,
      name: undefined,
      description: undefined,
      network_distribution_id: undefined
    }
  },
  validations() {
    return {
      name: { required },
      description: { required },
      network_distribution_id: { required },
    }
  },
  computed: {
    ...mapGetters({
      networkDistributions: types.NETWORK_DISTRIBUTIONS
    })
  },
  mounted() {
    this.loading = true
    this.getNetworkDistributions()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os modelos de distribuição das comissões. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        if (!this.$route.params.id) {
          this.loading = false
        }
      })
    if (this.$route.params.id) {
      this.getProductById(this.$route.params.id)
        .then(response => {
          const {
            name,
            description,
            network_distribution_id
          } = response.data
          this.name = name
          this.description = description
          this.network_distribution_id = network_distribution_id
          this.v$.$touch()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o produto selecionado. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    ...mapActions({
      saveProduct: types.SAVE_PRODUCT,
      getProductById: types.GET_PRODUCT,
      getNetworkDistributions: types.GET_NETWORK_DISTRIBUTIONS
    }),
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.saving = true
      const {
        name, 
        description, 
        network_distribution_id
      } = this
      let payload = {
        id: this.$route.params.id,
        name,
        description,
        network_distribution_id
      }
      this.saveProduct(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `O produto foi ${this.$route.params.id ? 'atualizado' : 'criado' } com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'products-list' })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o produto selecionado. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    }
  }
}
</script>
